// import { Logo } from './logo.js';
// import { infiniteMenu } from './infinite-menu.js';

// function Header(mainLogo,auxLogo,menuItems,menuTabOffset = 20, menuIndexOffset = 3) {
    
//     this.mainLogo = mainLogo;
//     this.auxLogo = auxLogo;
//     this.menuItems = menuItems;
//     this.menuTabOffset = menuTabOffset;
//     this.menuIndexOffset = menuIndexOffset;

//     this.createHeader = function() {
        
//         const headerElement = document.createElement('header');

//         //Create logo component
//         //We use two logos to switch between dark and light themes
//         const logoConstructor = new Logo(this.mainLogo.src,this.mainLogo.className);
//         const logoContainer = logoConstructor.createLogo();
//         const mainLogoElement = logoContainer.querySelector('.'+this.mainLogo.className);
//         mainLogoElement.classList.add('show');
//         const auxLogoConstructor = new Logo(this.auxLogo);
//         const auxLogoContainer = auxLogoConstructor.createLogo();
//         const auxLogoElement = auxLogoContainer.querySelector('.'+this.auxLogo.className);
//         logoContainer.appendChild(auxLogoElement);

//         headerElement.appendChild(logoContainer);

//         //Create navigation menu

//         const menuConstructor = new infiniteMenu(this.menuItems, this.menuTabOffset, this.menuIndexOffset);
//         //Menu button
//         const menuButton = menuConstructor.createMenuButton;
//         headerElement.appendChild(menuButton);
        
//         //Menu container
//         const menu = menuConstructor.createMenu();
//         headerElement.appendChild(menu);
//         this.animateMenu(menu);
//         this.activateButton(menuButton,menu,logoContainer);


//         return headerElement;

//     }


//     this.animateMenu = function(menu){
//         //First let's position our menu items using the "top property
//         const menuTabs = menu.querySelectorAll('.tab');
        
//         function setMenuTabs(){
//             menuTabs.forEach((tab,index) => {
//                 tab.style.transform = "translate(-100%,0) skewY(0)";
//                 tab.style.opacity = '0';
//                 tab.dataset.scrollPosition = (index-indexOffset)*menuTabOffset;
//                 tab.style.top = `${tab.dataset.scrollPosition}%`;
//                 tab.style.transitionDelay = `${0.1 * index}s`
//             });
//         }
        
//         setMenuTabs();

//         //Let's add a wheel event
//         menu.onwheel = scrollMenu;

//         //Some variables to estimate the scroll speed
//         let velocity = 0;
//         let scrollOffset = 0;
//         const dragCoefficient = 0.1;
//         const skewFactor = -2;
//         let requestId;

//         function scrollMenu(event){
//             event.preventDefault();
//             velocity = -parseFloat(event.deltaY*0.1);//Initial velocity
            
//             if(!requestId){//Initialize animation
//                 updateAnimation();
//             }
//         }

//         function updateAnimation(){
        
//             velocity = (1-dragCoefficient)*velocity; //Exponential velocity decay
//             scrollOffset = velocity; //update translation amount
//             menuTabs.forEach(tab => {
//                 tab.style.transition = 'transform 0.1s';//Change transition speed
//                 tab.dataset.scrollPosition = parseFloat(tab.dataset.scrollPosition) + scrollOffset;//Translate tabs
//                 //Set periodic boundary conditions, if top item goes beyond top boundary move the item  to the bottom of the menu
//                 if(parseFloat(tab.dataset.scrollPosition) <= -(this.menuIndexOffset+1)*this.menuTabOffset){
//                     tab.dataset.scrollPosition = parseFloat(tab.dataset.scrollPosition) + (menuTabs.length) * this.menuTabOffset;
//                 //If the bottom item goes beyond the bottom boundary move the  item to the top
//                 }else if(parseFloat(tab.dataset.scrollPosition) >= (menuTabs.length-1)*this.menuTabOffset){
//                     tab.dataset.scrollPosition = parseFloat(tab.dataset.scrollPosition) - (menuTabs.length) * this.menuTabOffset;
//                 }

//                 tab.style.top = `${tab.dataset.scrollPosition}%`;
//                 tab.style.transform = `skewY(${Math.max(Math.min(skewFactor*velocity,15),-15)}deg)`; //Add some velocity dependent deformation
//             });
        
//             //Set a threshold to stop the animation
//             if(Math.abs(velocity) > 0.0001){
//                 requestId = requestAnimationFrame(updateAnimation);
//             }else{
//                 requestId = null;
//                 //Reset the transition speed and deformation
//                 menuScrollFinished();
//             }

//         }

//         function menuScrollFinished(){
//             menuTabs.forEach((tab,index) => {
//                 tab.style.transform = "translate(0,0) skewY(0)";
//                 tab.style.transition = "transform 0.7s ease-in-out, opacity 1s ease-in-out"
//             });
//         }

//     }

//     this.activateButton = function(button,menu,logo){

//         const menuTabs = menu.querySelectorAll('.tab');
        
//         button.addEventListener('mouseenter',highlightMenu);
//         button.addEventListener('mouseleave',highlightMenu);

//         function highlightMenu(){
//             menu.classList.toggle('highlight');
//         }

//         button.addEventListener('click',showMenu);
        
//         function showMenu(){
//             const logoContainer = document.querySelector('logo')
//             if(menu.classList.contains('show')){//Close menu
//                 buttons.addEventListener('mouseenter',highlightMenu);
//                 buttons.addEventListener('mouseleave',highlightMenu);
//                 setMenuTabs();
//             }else{//Open menu
//                 menuTabs.forEach(tab => {
//                     tab.style.transform = "translate(0,0) skewY(0)";
//                     tab.style.opacity = "1";
//                 });
//                 buttonsContainer.removeEventListener('mouseenter',highlightMenu);
//                 buttonsContainer.removeEventListener('mouseleave',highlightMenu);
//             }
//             this.switchLogo(logo);
//             menuButton = menu.querySelector('#menu-button');
//             menuButton.classList.toggle('active');
//             closeButton = menu.querySelector('#close-button');
//             closeButton.classList.toggle('active');
//             menu.classList.toggle('show');
//         }
//     }

//     this.switchLogo = function(){
//         mainLogo.classList.toggle('show');
//         auxLogo.classList.toggle('show');
//     }

// }

// export { Header };