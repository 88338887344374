function Learn(){
    const content = document.createElement('div');
    content.classList.add('content');
    const title = document.createElement('h1');
    title.classList.add('teaser-title');
    title.textContent = 'KNOWLEDGE IS COMING SOON: ELEVATE YOUR DANCE JOURNEY';
    content.appendChild(title);
    const teaser = document.createElement('p');
    teaser.classList.add('teaser');
    teaser.innerHTML = 'Explore a curated collection of articles, tutorials, and videos designed to deepen your understanding of dance history, culture, and styles.<br><br>Become part of the community! <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhp4VmqFQfbsUJmGeT670cnJjmJ2HS69fqdhSJPMPrH9Q8Ug/viewform" rel="noopener noreferrer" target="_blank">JOIN</a> our mailing list for early access and exclusive updates.';
    content.appendChild(teaser);
    // const teaserCTA = document.createElement('p');
    // teaserCTA.classList.add('teaser-cta');
    // teaserCTA.innerHTML = 'Become part of the community! <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhp4VmqFQfbsUJmGeT670cnJjmJ2HS69fqdhSJPMPrH9Q8Ug/viewform" rel="noopener noreferrer">Join</> our mailing list and for early access and exclusive updates.'
    // content.appendChild(teaserCTA);

    return content;
}
export { Learn };