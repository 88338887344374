function Contact(){
    
    const content = document.createElement('div');
    content.classList.add('content');
    const contactUs = document.createElement('div');
    contactUs.classList.add('contact-us');
    // const contactUsText = document.createElement('div');
    // contactUsText.classList.add('contact-text');
    const contactUsTitle = document.createElement('h1');
    contactUsTitle.classList.add('contact-us-title');
    contactUsTitle.textContent = "WE'D LOVE TO HEAR FROM YOU";
    const contactUsPhrase = document.createElement('p');
    contactUsPhrase.classList.add('contact-us-phrase');
    contactUsPhrase.innerHTML = 'Share your thoughts, ask a question, or simply say hello!'
    contactUs.appendChild(contactUsTitle);
    contactUs.appendChild(contactUsPhrase);

    // contactUs.appendChild(contactUsText);
    const contactUsButton = document.createElement('a');
    contactUsButton.classList.add('contact-button');
    // contactUsButton.href = "mailto:contact@thedancethread.com?subject=Inquiry%20from%20The%20Dance%20Thread&body=Hi,%20I'd%20love%20to%20learn%20more%20about..."
    contactUsButton.href = "https://docs.google.com/forms/d/e/1FAIpQLSfhp4VmqFQfbsUJmGeT670cnJjmJ2HS69fqdhSJPMPrH9Q8Ug/viewform";
    contactUsButton.rel="noopener noreferrer";
    contactUsButton.target="_blank";
    contactUsButton.textContent = 'CONNECT';

    contactUs.appendChild(contactUsButton);
    
    // const nStripes = 20;
    // const stripeWidth = 100 / nStripes;
    // contactUs.appendChild(contactUsButton(nStripes,stripeWidth));

    content.appendChild(contactUs);

    return content;
}

// function contactUsButton(){
//     const contactUsButtonContainer = document.createElement('a');
//     contactUsButtonContainer.classList.add('contact-button');
//     contactUsButtonContainer.href = "mailto:contact@thedancethread.com?subject=Inquiry%20from%20The%20Dance%20Thread&body=Hi,%20I'd%20love%20to%20learn%20more%20about..."
//     // const contactButton = document.createElement('div');
//     // contactButton.classList.add('contact-button');
//     // contactButton.classList.add('dummy');
//     contactUsButtonContainer.textContent = 'CONNECT';
//     // contactUsButtonContainer.appendChild(contactButton);
//     // for(let i = 0; i < nStripes; i++){
//     //     const stripePercentage = i*stripeWidth;
//     //     const stripe = document.createElement('p');
//     //     stripe.classList.add('stripe');
//     //     stripe.textContent = 'CONNECT';
//     //     contactUsButtonContainer.appendChild(stripe);
//     //     if(i%2===0){
//     //         stripe.style.transform='translateX(50%) scaleX(2)';
//     //     }else{
//     //         stripe.style.transform='translateX(-50%) scaleX(2)';
//     //     }
//     //     stripe.style.clipPath=`inset(${stripePercentage+stripeWidth/2}% 0 ${100-stripePercentage-stripeWidth/2}% 0)`;
//     //     stripe.style.filter = 'blur(10px) drop-shadow(0 0 15px rgba(0, 0, 0, 0.5)';

//     // }
//     return contactUsButtonContainer;
// }

function animateContactButton(){
    const stripes = document.querySelectorAll('.stripe');
    stripes.forEach((stripe,index) => {
        const stripePercentage = index*5;
        stripe.style.transform = 'translateX(0) scaleX(1)';
        stripe.style.clipPath = `inset(${stripePercentage}% 0 ${100-stripePercentage-5}% 0)`;
        stripe.style.filter = 'none';
    });
    const contactButton = document.querySelector('contact-button');
    // setTimeout(() => {contactButton.style.opacity = '1';},1100);
}

export { Contact, animateContactButton };