//Styles, fonts and colors
import './style.css';
import './fonts-and-colors.css';

// //Header component
import { Header } from './components/header.js';
import { Home } from './pages/home.js';
import { About } from './pages/about.js';
import { Contact } from './pages/contact.js';
import { Hub } from './pages/hub.js';
import { Learn } from './pages/learn.js';

const pages = {
  home: Home(),
  about: About(),
  contact: Contact(),
  hub: Hub(),
  learn: Learn()
}

console.log(pages);

const page = document.createElement('div');
page.classList.add('page');

document.body.appendChild(page);

async function insertSVG() {
  const icon = await import(`./images/the-dance-thread-logo-dark-2.svg?raw`);
  
  const preload = document.createElement('div');
  preload.classList.add('preload');
  preload.innerHTML = icon.default;
  const svgIcon = preload.querySelector('svg');
  svgIcon.classList.add('animated-icon');
  
  document.body.appendChild(preload);

  svgIcon.addEventListener('load',animateIcon);
}

insertSVG();

const customDelays = [1.4,1.0,0.8,0.6,0.4,0.2,0.0];
function animateIcon() {

  const maps = document.querySelectorAll('.map');

  const theDance = document.querySelector('#the-dance');
  const cursiveLetters = document.querySelectorAll('[id^="cursive-"]');
  const separator = document.querySelector('#separator');

  maps.forEach(map => {
    map.classList.add('show');
  });

  setTimeout(() => {
    separator.classList.add('show');
    theDance.classList.add('show');

  },200);

  cursiveLetters.forEach((path,index) => {
    setTimeout(() => {
      path.classList.add('show');
    },2000+customDelays[index]*1000);
  });

    const fullIcon = document.querySelector('.animated-icon');
    setTimeout(() => {
        fullIcon.classList.add('move');
    }, 3600);

    const overlay = document.querySelector('.overlay');
    const homeScreen = document.querySelector('.home-screen');

    setTimeout(() => {
        const preload = document.querySelector('.preload');
        document.body.removeChild(preload);
        homeScreen.classList.add('show');
        overlay.classList.add('show');
    }, 5600);
}

import logoImage from './images/the-dance-thread-logo-dark-2.svg';
const welcomeLogo = {
  src: logoImage,
  className: 'logo'
}
const mainLogoSrc = {
  src: logoImage,
  className: 'main-logo'
}
import lightLogoImage from './images/the-dance-thread-logo-light-1.svg';
const auxLogoSrc = {
  src: lightLogoImage,
  className: 'aux-logo'
}
import danceVideo1 from './videos/homescreen1.mp4';
import welcome01 from './images/tdt-welcome01.jpg';
import danceVideo2 from './videos/homescreen2.mp4';
import welcome02 from './images/tdt-welcome02.jpg';
import danceVideo3 from './videos/homescreen3.mp4';
import welcome03 from './images/tdt-welcome03.jpg';
import danceVideo4 from './videos/homescreen4.mp4';
import welcome04 from './images/tdt-welcome04.jpg';

const elements = [
  {
    name: 'dance-video-1',
    src: danceVideo1,
    poster: welcome01,
    type: 'video/mp4'
  },
  {
    name: 'dance-video-2',
    src: danceVideo2,
    poster: welcome02,
    type: 'video/mp4'
  },
  {
    name: 'dance-video-3',
    src: danceVideo3,
    poster: welcome03,
    type: 'video/mp4'
  },
  {
    name: 'dance-video-4',
    src: danceVideo4,
    poster: welcome04,
    type: 'video/mp4'
  }
]

import { WelcomePage } from './components/welcome-page.js';


document.body.appendChild(WelcomePage(elements,welcomeLogo));

const startButton = document.querySelector('.get-started');
startButton.addEventListener('click',activatePage);
function activatePage(){
  // const icon = document.querySelector('.menu-logo');
  const homeScreen = document.querySelector('.home-screen');
  page.classList.add('active');
  mainLogoContainer.classList.add('show');
  mainLogo.classList.add('show');
  auxLogoContainer.classList.add('show');
  setTimeout(() => {
    document.body.removeChild(homeScreen);  
  },1500);
}

//Transition
const transitionScreen = document.createElement('div');
transitionScreen.classList.add('transition-screen');
page.appendChild(transitionScreen);

// import { doubleLogo } from './components/logo.js';
import { Logo } from './components/logo.js';

const mainLogoConstructor = new Logo(mainLogoSrc);
const mainLogoContainer = mainLogoConstructor.createLogo();
const mainLogo = mainLogoContainer.querySelector('.'+mainLogoSrc.className);
mainLogo.addEventListener('click',goHome);
page.appendChild(mainLogoContainer);
const auxLogoConstructor = new Logo(auxLogoSrc);
const auxLogoContainer = auxLogoConstructor.createLogo();
const auxLogo = auxLogoContainer.querySelector('.'+auxLogoSrc.className);
page.appendChild(auxLogoContainer);

import { MenuButton } from './components/infinite-menu.js';

const menuButtonConstructor = new MenuButton();
const menuButton = menuButtonConstructor.createMenuButton();
page.appendChild(menuButton);

const menuItems = [
  {
    name: 'HOME',
    page: 'home'
  },
  {
    name: 'ABOUT',
    page: 'about'
  },
  {
    name: 'HUB',
    page: 'hub'
  },
  {
    name: 'STORE',
    page: 'store'
  },
  {
    name: 'LEARN',
    page: 'learn'
  },
  {
    name: 'CONTACT',
    page: 'contact'
  }
]

import { InfiniteMenu } from './components/infinite-menu.js';

const menuConstructor = new InfiniteMenu(menuItems);
const menu = menuConstructor.createMenu();

page.appendChild(menu);

// //Menu animation

//First let's position our menu items using the "top property
const menuTabOffset = 20;//Change these two variables to place the home item in the desired position
const indexOffset = 5;

const menuTabs = menu.querySelectorAll('.tab');
function setMenuTabs(){
  menuTabs.forEach((tab,index) => {
  tab.style.transform = "translate(-100%,0) skewY(0)";
  tab.style.opacity = '0';
  tab.dataset.scrollPosition = (index-indexOffset)*menuTabOffset;
  tab.style.top = `${tab.dataset.scrollPosition}%`;
  tab.style.transitionDelay = `${0.1 * index}s`
  });
}
setMenuTabs();

//Let's add a wheel event
menu.onwheel = scrollMenu;
//Handle touch events
let touchStartY = 0;
menu.addEventListener("touchstart",handleTouchStart);
menu.addEventListener("touchmove",scrollMenu);

//Some variables to estimate the scroll speed
let velocity = 0;
let scrollOffset = 0;
const dragCoefficient = 0.1;
const skewFactor = -2;
let requestId;

function scrollMenu(event){
  
  event.preventDefault();
  velocity = -parseFloat(getDeltaY(event)*0.1);
  
  if(!requestId){
    updateAnimation();
  }
}

function getDeltaY(event){
  if(event.type === 'wheel'){
    return event.deltaY;
  }else if(event.type === 'touchmove'){
    // if(!touchStartY){
    //   touchStartY = event.touches[0].clientY;
    //   return 0;
    // }
    const touchEndY = event.touches[0].clientY;
    const deltaY = touchStartY - touchEndY;
    touchStartY = touchEndY;
    return deltaY;
  }
  return 0;
}

function handleTouchStart(event){
  touchStartY = event.touches[0].clientY;
}

function updateAnimation(){

  velocity = (1-dragCoefficient)*velocity ;
  scrollOffset = velocity;
  
  menuTabs.forEach(tab => {
    tab.style.transition = 'transform 0.1s';
    tab.dataset.scrollPosition = parseFloat(tab.dataset.scrollPosition) + scrollOffset;
    if(parseFloat(tab.dataset.scrollPosition) <= -(indexOffset+1)*menuTabOffset){
      tab.dataset.scrollPosition = parseFloat(tab.dataset.scrollPosition) + (menuTabs.length) * menuTabOffset;
    }else if(parseFloat(tab.dataset.scrollPosition) >= (menuTabs.length-1)*menuTabOffset){
      tab.dataset.scrollPosition = parseFloat(tab.dataset.scrollPosition) - (menuTabs.length) * menuTabOffset;
    }
    tab.style.top = `${tab.dataset.scrollPosition}%`;
    tab.style.transform = `skewY(${Math.max(Math.min(skewFactor*velocity,15),-15)}deg)`;
  });

  if(Math.abs(velocity) > 0.0001){
    requestId = requestAnimationFrame(updateAnimation);
  }else{
    requestId = null;
    menuScrollFinished();
  }

}

function menuScrollFinished(){
  menuTabs.forEach((tab,index) => {
    tab.style.transform = "translate(0,0) skewY(0)";
    tab.style.transition = "transform 0.7s ease-in-out, opacity 1s ease-in-out"
  });
  touchStartY = 0;
}

menuButton.addEventListener('mouseenter',highlightMenu);
menuButton.addEventListener('mouseleave',highlightMenu);
menuButton.addEventListener('click',handleClick);

function handleClick() {
  if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
    // Mobile behavior
    if (!menu.classList.contains('show')) { 
      // Menu is closed
      if(!menu.classList.contains('highlight')){
        highlightMenu();
      }
      setTimeout(showMenu, 300); // Delay to show menu
    } else {
      // Menu is open
      showMenu();
      setTimeout(highlightMenu, 300);
    }
  } else {
    // Desktop behavior
    showMenu();
  }
}

function highlightMenu(){
  menu.classList.toggle('highlight');
}

// // buttonsContainer.addEventListener('click',showMenu);
function showMenu(){
    if(menu.classList.contains('show')){
      menuButton.addEventListener('mouseenter',highlightMenu);
      menuButton.addEventListener('mouseleave',highlightMenu);
      setMenuTabs();
    }else{
      menuTabs.forEach(tab => {
        tab.style.transform = "translate(0,0) skewY(0)";
        tab.style.opacity = "1";
      });
      menuButton.removeEventListener('mouseenter',highlightMenu);
      menuButton.removeEventListener('mouseleave',highlightMenu);
    }
    mainLogo.classList.toggle('show');
    footer.classList.toggle('dark');
    auxLogo.classList.toggle('show');
    const openButton = menuButton.querySelector('#menu-button');
    openButton.classList.toggle('active');
    const closeButton = menuButton.querySelector('#close-button');
    closeButton.classList.toggle('active');
    menu.classList.toggle('show');
}

//Footer
import { Footer } from './components/footer.js';

const companyCopyright = 'Durounseki';
const companySns = [
  {
      name: "facebook",
      url: "https://www.facebook.com/share/c3Q8uPitFzd3RciE/?mibextid=LQQJ4d",
      faClass: "fa-brands fa-square-facebook",
  },
  {
      name: "instagram",
      url: "https://www.instagram.com/thedancethread?igsh=b3c2M216eHNmNjh4",
      faClass: "fa-brands fa-instagram",
  }
]
const DesignCredits = {
  text: "Web design by",
  url: "https://github.com/Durounseki",
  faClass: "fa-brands fa-square-github",
  creditor: "Durounseki",
}

const footerConstructor = new Footer(companyCopyright,companySns,DesignCredits);//Instantiate a Footer object

const footer = footerConstructor.createFooter();

page.appendChild(footer);

//page content

const pageContent = document.createElement('div');
pageContent.classList.add('page-content');

page.appendChild(pages['home']);

//Load page
//Attach event listeners to each menu-item

menuTabs.forEach(tab => {
  tab.addEventListener('click', changePage);
});

function goHome(){
  
  const nextPage = pages['home'];
  const currentPage = document.querySelector('.content');
  page.removeChild(currentPage);
    //Load page
  page.appendChild(nextPage);

}
function changePage(event){
  //Clear current content
  const tab = event.target;
  if(tab.dataset.page==='store'){
    window.open("https://thedancethread.com/", "_blank", "noopener noreferrer");
  }else{
    const nextPage = pages[tab.dataset.page];
    const currentPage = document.querySelector('.content');
    page.removeChild(currentPage);
    //Load page
    page.appendChild(nextPage);
    showMenu();
    setTimeout(()=>{highlightMenu();},300); 
  }
}
