import yasmineColor from '.././images/yasmine-color.jpeg';
import yasmineFilter from '.././images/yasmine-filter.svg';
import lisaColor from '.././images/lisa-color.jpeg';
import lisaFilter from '.././images/lisa-filter.svg';
import chrisColor from '.././images/chris-color.jpeg';
import chrisFilter from '.././images/chris-filter-2.svg';

const members = [
    {
        name: 'Yasmine Allen',
        role: 'Founder',
        colorPic: yasmineColor,
        filterPic: yasmineFilter 
    },
    {
        name: 'Lisa Holden',
        role: 'Digital Marketing',
        colorPic: lisaColor,
        filterPic: lisaFilter
    },
    {
        name: 'Christian Esparza',
        role: 'UI/UX Designer',
        colorPic: chrisColor,
        filterPic: chrisFilter
    }
]

function About(){
    
    const content = document.createElement('div');
    content.classList.add('content');
    const mission = document.createElement('div');
    mission.classList.add('mission');
    const missionTitle = document.createElement('h1');
    missionTitle.textContent = 'OUR MISSION';
    const missionText = document.createElement('p');
    missionText.innerHTML = 'At <span>The Dance Thread</span>, we believe dance is for every body. Our goal is to create a judgment-free zone where you can express yourself authentically, celebrate your unique style, and find a community that welcomes you with open arms. We\'re thrilled to be launching a new line of dancewear and uniforms designed with all dancers in mind, empowering everyone to feel confident and comfortable in their own skin.'
    const missionCTA = document.createElement('p');
    missionCTA.textContent = 'Join our movement and discover a dance community that truly celebrates you.'
    mission.appendChild(missionTitle);
    mission.appendChild(missionText);
    mission.appendChild(missionCTA);

    content.appendChild(mission);

    const teamContainer = document.createElement('div');
    teamContainer.classList.add('team-container');
    const teamTitle = document.createElement('h1');
    teamTitle.textContent = 'OUR TALENTED TEAM';
    teamContainer.appendChild(teamTitle);
    const team = document.createElement('div');
    team.classList.add('team');
    for(let member of members){
        team.appendChild(teamMember(member));
    }
    teamContainer.appendChild(team);
    content.appendChild(teamContainer);

    const partners = document.createElement('div');
    partners.classList.add('partners');
    const partnersTitle = document.createElement('h1');
    partnersTitle.classList.add('partners-title');
    partnersTitle.textContent = 'JOIN US'
    partners.appendChild(partnersTitle);
    const partnersText = document.createElement('p');
    partnersText.classList.add('partners-text');
    partnersText.textContent = 'The Dance Thread is more than just a platform; it\'s a thriving, inclusive community of dance enthusiasts across Asia. We\'re dedicated to showcasing and amplifying the incredible work of dance studios, companies, and event organizers like you.'
    partners.appendChild(partnersText);
    const callToAction = document.createElement('p');
    callToAction.classList.add('cta');
    callToAction.innerHTML = 'Interested in joining the movement? <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhp4VmqFQfbsUJmGeT670cnJjmJ2HS69fqdhSJPMPrH9Q8Ug/viewform" rel="noopener noreferrer" target="_blank">CONTACT</a> us today to get started!'
    partners.appendChild(callToAction);

    content.appendChild(partners);

    return content;

}

function teamMember(member){
    
    const memberContainer = document.createElement('div');
    memberContainer.classList.add('team-member');

    const colorPictureContainer = document.createElement('div');
    colorPictureContainer.classList.add('member-picture');
    colorPictureContainer.classList.add('color');
    const colorPicture = document.createElement('img');
    colorPicture.src = member.colorPic;
    colorPicture.classList.add('color-pic');
    
    const memberName = document.createElement('p');
    memberName.classList.add('member-name');
    memberName.textContent = member.name;
    const memberRole = document.createElement('p');
    memberRole.classList.add('member-role');
    memberRole.textContent = member.role;
    memberContainer.appendChild(memberName);
    memberContainer.appendChild(memberRole);
    colorPictureContainer.appendChild(colorPicture);

    memberContainer.appendChild(colorPictureContainer);

    return memberContainer;
}

export { About };