function Video(video){

    this.video = video;

    this.createVideo = function(){
        const video = document.createElement('video');
        video.id = this.video.name;
        video.autoplay = true;
        video.muted = true;
        video.loop = true;

        video.poster = this.video.poster;

        const source = document.createElement('source');
        source.src = this.video.src;
        source.type = this.video.type;
        video.appendChild(source);

        return video;
    }

}

function Carousel(elements){

    this.elements = elements;

    this.createCarousel = function(){
        const carousel = document.createElement('div');
        carousel.classList.add('carousel');
        for(let element of this.elements){
            carousel.appendChild(this.createFrame(element));
        }
        return carousel;
    }

    this.createFrame = function(element) {
        if(element.type.includes('image')){
            const imageElement = new Image();
            imageElement.src = element.src;
            imageElement.classList.add('frame');
            return imageElement;
        }else if(element.type.includes('video')){
            const videoConstructor =  new Video(element);
            const videoElement = videoConstructor.createVideo();
            videoElement.classList.add('frame');
            return videoElement;
        }
    }
}

export { Carousel, Video };