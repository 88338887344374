import { Logo } from './logo.js';
import { Carousel } from './video.js';

function WelcomePage(elements,logoSrc){

    const homeScreen = document.createElement('div');
    homeScreen.classList.add('home-screen');

    const videoSliderComponent = new Carousel(elements);
    const videoSlider = videoSliderComponent.createCarousel();
    const videoSliderClone = videoSliderComponent.createCarousel();
    videoSliderClone.classList.add('clone');

    homeScreen.appendChild(videoSlider);
    homeScreen.appendChild(videoSliderClone);

    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    
    // //Create logo component
    const logo = new Logo(logoSrc);
    const logoComponent = logo.createLogo();
    overlay.appendChild(logoComponent);

    const introduction = document.createElement('h1');
    introduction.classList.add('introduction');
    introduction.textContent = "Asia's Premier Inclusive Dance Community & Event Platform"
    overlay.appendChild(introduction);

    const getStarted = document.createElement('button');
    getStarted.classList.add('get-started');
    getStarted.textContent = "Start Dancing";
    overlay.appendChild(getStarted);


    homeScreen.appendChild(overlay);


    return homeScreen;
}

export { WelcomePage };