function Logo(logo) {
    this.logo = logo;

    this.createLogo = function() {
        const logoContainer = document.createElement('div');
        logoContainer.classList.add('logo-container');

        const logoImage = new Image();
        logoImage.src = this.logo.src;
        logoImage.classList.add(this.logo.className); 

        logoContainer.appendChild(logoImage);
        return logoContainer;
    };
}
// function Logo(logoSrc, className = 'logo') {
//     this.logoSrc = logoSrc;
//     this.className = className; // Allow customization of class name

//     this.createLogo = function() {
//         const logoContainer = document.createElement('div');
//         logoContainer.classList.add('logo-container');

//         const logoImage = new Image();
//         logoImage.src = this.logoSrc;
//         logoImage.classList.add(this.className); 

//         logoContainer.appendChild(logoImage);
//         return logoContainer;
//     };
// }

function doubleLogo(mainLogo, auxLogo){
    
    this.mainLogo = mainLogo;
    this.auxLogo = auxLogo;

    this.createLogo = function(){
        //We use two logos to switch between dark and light themes
        const logoConstructor = new Logo(this.mainLogo);
        const logoContainer = logoConstructor.createLogo();
        const mainLogoElement = logoContainer.querySelector('.'+this.mainLogo.className);
        mainLogoElement.classList.add('show');
        const auxLogoConstructor = new Logo(this.auxLogo);
        const auxLogoContainer = auxLogoConstructor.createLogo();
        const auxLogoElement = auxLogoContainer.querySelector('.'+this.auxLogo.className);
        logoContainer.appendChild(auxLogoElement);

        return logoContainer;
    }

}

function Icon(iconSrc, className = 'icon'){
    this.iconSrc = iconSrc;
    this.className = className;

    this.createIcon = function() {

        const container = document.createElement('div'); // Create a regular div

        return new Promise((resolve, reject) => {
            import(`../images/${this.iconSrc}?raw`)
              .then(svgModule => {
                const svgContent = svgModule.default;
                container.innerHTML = svgContent;
                const svgElement = container.querySelector('svg');
                svgElement.classList.add(this.className);
                
                // Resolve the promise with the svgElement
                resolve(svgElement);
              })
              .catch(err => reject(err)); // Reject the promise on error
          });
        
        // //Import svg using require (add query string "raw")
        // import(`../images/${this.iconSrc}?raw`).then(
        //     svgModule => {
        //         const svgContent = svgModule.default;

        //         // const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg"); //Use if svg file does not contain namespace
                
        //         container.innerHTML = svgContent;
        //     }
        // ).catch(err => console.error("Error loading SVG:", err));

        // const svgElement = container.querySelector('svg');
        // svgElement.classList.add(that.className);

        // return svgElement;

    }
}

export { Logo, doubleLogo, Icon }; // Export for importing in other files
