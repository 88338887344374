import placeHolderThumb from '.././images/the-dance-thread-logo-dark-2.svg';
function EventSchedule(danceEvents) {
    this.danceEvents = danceEvents;

    this.createSchedule = function () {
        const scheduleContainer = document.createElement('div');
        scheduleContainer.classList.add('schedule-container');

        // //Menu
        // const hubMenu = document.createElement('div');
        // hubMenu.classList.add('hub-menu');
        // const hubItems = ['Events', 'Socials', 'Classes'];
        // hubItems.forEach(item =>{
        //     const hubItem = document.createElement('div')
        //     hubItem.textContent = item;
        //     hubMenu.appendChild(hubItem);
        // });
        // scheduleContainer.appendChild(hubMenu);

        // Create schedule items for each dance style (Latin, Hip-Hop, Heels)
        const styles = ['Latin', 'Hip-Hop', 'Heels'];
        styles.forEach(style => {
            const scheduleItem = this.createScheduleItem(style);
            scheduleContainer.appendChild(scheduleItem);
        });

        return scheduleContainer;
    };

    this.createScheduleItem = function (style) {
        const scheduleItem = document.createElement('div');
        scheduleItem.classList.add('schedule-item');

        const heading = document.createElement('h2');
        heading.textContent = style;
        scheduleItem.appendChild(heading);

        const scheduleGrid = document.createElement('div');
        scheduleGrid.classList.add('schedule-grid');
        const schedule = document.createElement('div');
        schedule.classList.add('schedule');
        scheduleGrid.appendChild(schedule)
        scheduleItem.appendChild(scheduleGrid);

        // Filter events by style and create event items
        const filteredEvents = this.danceEvents.filter(event => event.style === style);
        filteredEvents.forEach(event => {
            //Find the index of the event in the unfiltered array
            const eventIndex = this.danceEvents.findIndex(e => e === event);
            const eventItem = this.createEventItem(event,eventIndex);
            schedule.appendChild(eventItem);
        });

        if (filteredEvents.length === 0) {
            schedule.appendChild(this.createPlaceholderEventItem());
        }

        return scheduleItem;
    };

    this.createEventItem = function (danceEvent,index) {
        const eventItem = document.createElement('div');
        eventItem.classList.add('event-item');
        eventItem.dataset.eventIndex = index;

        const eventDate = document.createElement('div');
        eventDate.classList.add('event-date');

        const month = document.createElement('p');
        month.textContent = danceEvent.details.dates.split(' ')[1].toUpperCase().slice(0, 3); 
        eventDate.appendChild(month);

        const day = document.createElement('p');
        day.textContent = danceEvent.details.dates.split(' ')[2]; 
        eventDate.appendChild(day);

        eventItem.appendChild(eventDate);

        // Add event thumbnail (first slide)
        const eventThumb = document.createElement('div');
        eventThumb.classList.add('event-thumb');
        const img = document.createElement('img');
        img.src = danceEvent.slides[0].src; 
        img.alt = danceEvent.details.name; 
        eventThumb.appendChild(img);
        eventItem.appendChild(eventThumb);

        const eventInfo = document.createElement('div');
        eventInfo.classList.add('event-summary');

        const eventName = document.createElement('p');
        eventName.textContent = danceEvent.details.name; 
        eventInfo.appendChild(eventName);

        const eventVenue = document.createElement('p');
        eventVenue.textContent = danceEvent.details.venue.name; 
        eventInfo.appendChild(eventVenue);

        eventItem.appendChild(eventInfo);

        return eventItem;
    };

    this.createPlaceholderEventItem = function () {
        const eventItem = document.createElement('div');
        eventItem.classList.add('event-item');
        eventItem.dataset.eventIndex = -1;

        const eventDate = document.createElement('div');
        eventDate.classList.add('event-date');
        eventDate.innerHTML = `
            <p>MON</p>
            <p>00</p>
        `; // Set the date to "MON 00"
        eventItem.appendChild(eventDate);

        const eventThumb = document.createElement('div');
        eventThumb.classList.add('event-thumb');
        const img = document.createElement('img');
        img.src = placeHolderThumb; // Replace with the actual path to your placeholder image
        img.alt = 'Coming Soon';
        eventThumb.appendChild(img);
        eventItem.appendChild(eventThumb);

        const eventInfo = document.createElement('div');
        eventInfo.classList.add('event-summary');
        eventInfo.innerHTML = `
            <p>Coming soon</p>
            <p></p>
        `; // Set the event name and venue to empty paragraphs
        eventItem.appendChild(eventInfo);

        return eventItem;
    };
}

export {EventSchedule};