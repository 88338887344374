import { Slider } from "./slideshow";

function EventInfo(danceEvent){
    this.danceEvent = danceEvent;

    this.createEventInfo = function(){

        const infoContainer = document.createElement('div');
        infoContainer.classList.add('event-info');
        
        //Event name
        const name = document.createElement('h1');
        name.classList.add('event-name');
        name.textContent = this.danceEvent.details.name;
        infoContainer.appendChild(name);
        //Event media, flyer, photos, etc.
        const sliderConstructor = new Slider(this.danceEvent.slides);
        const slider = sliderConstructor.createSlider();
        infoContainer.appendChild(slider);

        
        //Highlight slide in view
        const indicators = slider.querySelectorAll('.slide-controls a');
        const observerOptions = {
            root: slider.querySelector('.slides'),
            threshold: 0.5
        };

        indicators.forEach(indicator => {
            const targetSlide = slider.querySelector(indicator.getAttribute('href'));

            const observer = new IntersectionObserver((entries)=>{
                entries.forEach(entry => {
                    if(entry.isIntersecting){
                        indicator.classList.add('active');
                    }else{
                        indicator.classList.remove('active');
                    }
                });
            }, observerOptions);
            observer.observe(targetSlide);
        });

        //Event information
        infoContainer.appendChild(this.createInfoPanel(this.danceEvent.details));

        return infoContainer;
    }

    this.createInfoPanel = function(details){

        const panel = document.createElement('div');
        panel.classList.add('event-details');
        
        const datesContainer = document.createElement('div');
        const datesIcon = document.createElement('i');
        datesIcon.classList.add('fa-regular', 'fa-calendar');
        const dates = document.createElement('p');
        dates.textContent = details.dates;
        if(details.dates){
            datesContainer.appendChild(datesIcon);   
        }
        datesContainer.appendChild(dates);

        const venueContainer = document.createElement('div');
        const venueIcon = document.createElement('i');
        venueIcon.classList.add('fa-regular', 'fa-map');
        const venue = document.createElement('a');
        if(details.venue.url){
            venue.href = details.venue.url;
            venue.target = "_blank";
            venue.rel = "noopener noreferrer";
        }
        venue.textContent = details.venue.name;
        if(details.venue.name){
            venueContainer.appendChild(venueIcon);
        }
        venueContainer.appendChild(venue);

        const description = document.createElement('div');
        description.innerHTML = details.description;
        panel.appendChild(description);

        const extraInfo = document.createElement('div');
        extraInfo.classList.add('event-extra-info');
        // panel.appendChild(name);
        extraInfo.appendChild(datesContainer);
        extraInfo.appendChild(venueContainer);
        //Event links
        extraInfo.appendChild(this.createLinks(this.danceEvent.links));
        panel.appendChild(extraInfo);

        return panel;
    }

    this.createLinks = function(links){
        
        const linksContainer = document.createElement('div');
        linksContainer.classList.add('event-links');
            
        for(let link of links){
            linksContainer.appendChild(this.createLinkIcon(link));
        }

        return linksContainer;
    }

    this.createLinkIcon = function(link){
        //Icon FontAwesome
        const icon = document.createElement('i');
        icon.classList.add(...link.faClass.split(' ')); //allow multiple classes from Font Awesome
        //Accessibility
        icon.setAttribute('aria-label', link.name);
        
        if(link.url){
            const anchor = document.createElement('a');
            anchor.classList.add('event-link');
            anchor.href = link.url;
            anchor.target = "_blank";
            anchor.rel = "noopener noreferrer";
            anchor.appendChild(icon);
            return anchor;
        }else{
            return icon;
        }
    }

}

export {EventInfo};