function InfiniteMenu(menuItems, menuTabOffset = 20, menuIndexOffset = 3){

    this.menuItems = menuItems;
    this.menuTabOffset = menuTabOffset;
    this.menuIndexOffset = menuIndexOffset

    this.createMenu = function(){

        const menuContainer = document.createElement('div');
        menuContainer.classList.add('menu-container');

        for(let i = 0; i < 2; i++){
            for(let item of this.menuItems){
                menuContainer.appendChild(this.createMenuTab(item));
            }
        }

        return menuContainer;
    } 

    this.createMenuTab = function(item){
        

        const tab = document.createElement('p');
        tab.classList.add('tab');
        tab.textContent = item.name;
        tab.dataset.page = item.page;
        
        return tab;
        
    }

}

function MenuButton(){

    this.createMenuButton = function(){
        
        const buttonsContainer = document.createElement('div');
        buttonsContainer.classList.add('buttons-container');
        
        const buttons = document.createElement('div');
        buttons.classList.add('buttons');
        
        const menuButton = document.createElement('div');
        menuButton.classList.add('button');
        menuButton.classList.add('active');
        menuButton.id = "menu-button";
        menuButton.textContent = "MENU";
        
        const closeButton = document.createElement('div');
        closeButton.classList.add('button');
        closeButton.id = 'close-button';
        closeButton.textContent = "CLOSE";
        
        buttons.appendChild(menuButton);
        buttons.appendChild(closeButton);
        buttonsContainer.appendChild(buttons);

        return buttonsContainer;
    }

}

export { InfiniteMenu, MenuButton };