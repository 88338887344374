// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Audiowide/Audiowide-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/intro/IntroDemoBlackCaps.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --main-dark: #3A343E;
    --main-accent: #BE4F4F;
    --secondary-accent: #4FBEBE;
    --main-light:  #F1EEE8;
    --secondary-light: #E2D4D4;
    --bg-medium: #AAA4AC;

}

@font-face {
    font-family: 'Audiowide';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Intro';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
    font-weight: bold;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/fonts-and-colors.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,2BAA2B;IAC3B,sBAAsB;IACtB,0BAA0B;IAC1B,oBAAoB;;AAExB;;AAEA;IACI,wBAAwB;IACxB,+DAAsE;IACtE,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,+DAAmE;IACnE,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[":root{\n    --main-dark: #3A343E;\n    --main-accent: #BE4F4F;\n    --secondary-accent: #4FBEBE;\n    --main-light:  #F1EEE8;\n    --secondary-light: #E2D4D4;\n    --bg-medium: #AAA4AC;\n\n}\n\n@font-face {\n    font-family: 'Audiowide';\n    src: url('./fonts/Audiowide/Audiowide-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Intro';\n    src: url('./fonts/intro/IntroDemoBlackCaps.otf') format('opentype');\n    font-weight: bold;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
