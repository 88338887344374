import { EventInfo } from "../components/event-info";
import { EventSchedule } from "../components/event-schedule";

// import jeju from '.././images/jeju.jpeg';
//SBTS
import sbts from '.././images/sbts.png';
import sbtsWarmup from '.././images/SBTSWParty.PNG';
import sbtsPre from '.././images/SBTSPParty.PNG';
import sbtsMaster from '.././images/SBTSMasterClass.PNG';

//LBJ
import linking from '.././images/linkingBachata.jpeg';
import linkingFriday from '.././images/linking1.jpeg';
import linkingSaturday from '.././images/linking2.jpeg';
import linkingSunday from '.././images/linking3.jpeg';

//Placeholder
import placeholderImage from '.././images/placeholder01.jpg';


const danceEvents = [
    
    {
        style: 'Latin',
        details: {
            name: 'Salsa Bachata Temptation Singapore',
            dates: '2024 August 09',
            venue: {
                name: 'MAX Atria Garnet',
                url: 'https://www.google.com/maps/place/Singapore+EXPO+Meeting+Rooms/@1.3332953,103.9567683,15z/data=!4m6!3m5!1s0x31da3d29b4a86847:0x62aaab711af2fcdf!8m2!3d1.3332953!4d103.9567683!16s%2Fg%2F11btww6m0q?entry=ttu'
            },
            description: `
                <div class="event-ad">
                    <h2 class="ad-headline">Experience the energy of Asia's hottest Latin dance festival!</h2>
                    <p class="ad-body">
                        Get ready to learn from <span class="highlight">world-class instructors</span>, 
                        witness incredible performances, and <span class="highlight">party all night long</span> with 
                        the best dancers in Asia and <span class="highlight">renowned DJs</span>.
                    </p>
                    <p class="ad-cta">Don't miss out on this unforgettable experience!</p>
                </div>
            `
        },
        slides: [
            {
                name: 'sbts-1',
                src: sbts
            },
            {
                name: 'sbts-2',
                src: sbtsWarmup
            },
            {
                name: 'sbts-3',
                src: sbtsPre
            },
            {
                name: 'sbts-4',
                src: sbtsMaster
            }
        ],
        links: [
            {
                name: "website",
                url: "https://salsabachatatemptation.com/",
                faClass: "fa-solid fa-globe",
            },
            {
                name: "facebook",
                url: "https://www.facebook.com/SBTS2023",
                faClass: "fa-brands fa-square-facebook",
            },
            {
                name: "instagram",
                url: "https://www.instagram.com/Salsabachatatemptationsg/",
                faClass: "fa-brands fa-instagram",
            },
            {
                name: "youtube",
                url: "https://www.youtube.com/channel/UCnFxI6FMl4lAxA1UmY6d2ng",
                faClass: "fa-brands fa-youtube",
            }
        ]
    },
    {
        style: 'Latin',
        details: {
            name: 'Alonso & Noelia: Linking Japan Bachata Festival',
            dates: '2024 November 15',
            venue: {
                name: 'Tokyo',
            },
            description: `
                <div class="event-ad">
                    <h2 class="ad-headline">Connect with the world of bachata in the heart of Tokyo.</h2>
                    <p class="ad-body">Get exclusive training from 
                        <span class="highlight">Alonso y Noelia</span>, the 
                        World Bachata Masters silver medalists. 
                        Join our passionate community for social dancing and unforgettable parties.
                    </p>
                    <p class="ad-cta">Don't miss this chance to elevate your bachata experience!</p>
                </div>
            `
        },
        slides: [
                {
                    name: 'linking-1',
                    src: linking
                },
                {
                    name: 'linking-2',
                    src: linkingFriday
                },
                {
                    name: 'linking-3',
                    src: linkingSaturday
                },
                {
                    name: 'linking-4',
                    src: linkingSunday
                },
        ],
        links: [
            {
                name: "facebook",
                url: "https://www.facebook.com/events/852745789726373/?active_tab=discussion",
                faClass: "fa-brands fa-square-facebook",
            },
            {
                name: "instagram",
                url: "https://www.instagram.com/linkingjapan/",
                faClass: "fa-brands fa-instagram",
            },
        ]
    },
]

const eventPlaceHolder = {
    details: {
        name: 'Join The Dance Thread',
        dates: '',
        venue: {
            name: '',
        },
        description: `
            <div class="event-ad">
                <h2 class="ad-headline">Grow your dance community</h2>
                <p class="ad-body">
                The Dance Thread is your gateway to Asia's thriving dance scene. Gain exposure, attract new students, and fill your events with passionate dancers.
                </p>
                <p class="ad-cta"><a href="https://docs.google.com/forms/d/e/1FAIpQLSfhp4VmqFQfbsUJmGeT670cnJjmJ2HS69fqdhSJPMPrH9Q8Ug/viewform" rel="noopener noreferrer" target="_blank">Reach out</a> to get listed!</p>
            </div>
        `
    },
    slides: [
            {
                name: 'The Dance Thread',
                src: placeholderImage
            }
    ],
    links: [
    ]
} 

function Hub(){
    const content = document.createElement('div');
    content.classList.add('content');
    const hubContainer = document.createElement('div');
    hubContainer.classList.add('hub-container');

    content.append(hubContainer);

    //Event information
    const eventInfoContainer = document.createElement('div');
    eventInfoContainer.classList.add('event-info-container');
    hubContainer.appendChild(eventInfoContainer);

    displayHubContent();

    //Separator
    const separator = document.createElement('div');
    separator.classList.add('hub-separator');
    hubContainer.appendChild(separator);

    const eventScheduleConstructor = new EventSchedule(danceEvents);
    const eventSchedule = eventScheduleConstructor.createSchedule();
    const scheduleContainer =document.createElement('div');
    scheduleContainer.classList.add('schedule-wrapper');
    //Hub menu
    const hubMenu = document.createElement('div');
    hubMenu.classList.add('hub-menu');
    const hubItems = ['Events', 'Classes', 'Socials'];
    hubItems.forEach(item =>{
        const hubItem = document.createElement('div')
        hubItem.textContent = item;
        hubItem.dataset.section = item.toLowerCase();
        hubMenu.appendChild(hubItem);
    });
    hubMenu.firstChild.classList.add('active');
    scheduleContainer.appendChild(hubMenu);
    
    scheduleContainer.appendChild(eventSchedule);
    hubContainer.appendChild(scheduleContainer);
    
    const scheduleItems = eventSchedule.querySelectorAll('.schedule-item');
    scheduleItems.forEach(item => {
        //Show events thumbnails on the schedule
        const itemHeader = item.querySelector('h2');
        itemHeader.addEventListener('click',displaySchedule);
        //Show the event details on the info container
        const eventItems = item.querySelectorAll('.event-item');
        eventItems.forEach(eventItem => {
            eventItem.addEventListener('click',displayHubContent);
        });
    });
    function displaySchedule(event){
        const scheduleGrid = event.target.nextElementSibling;
        scheduleGrid.classList.toggle('active');
    }

    //Dynamic content change
    function displayHubContent(event){
        //For now let's just remove the current content and immediately replace it for the new content
        eventInfoContainer.innerHTML = '';
        //Then let's create a new instance of the desired event info
        let danceEventIndex;
        if(event){
            danceEventIndex = event.target.closest('.event-item').dataset.eventIndex;
        }else{
            danceEventIndex = 0;
        }
        let infoConstructor;
        if(danceEventIndex>=0){
            infoConstructor = new EventInfo(danceEvents[danceEventIndex]);
        }else{
            infoConstructor =  new EventInfo(eventPlaceHolder);
        }
        const eventInfo = infoConstructor.createEventInfo();
        //Update content
        eventInfoContainer.appendChild(eventInfo);
    }

    const tabs = hubMenu.querySelectorAll('div');
    tabs.forEach(tab => {
        tab.addEventListener('click',changeSection);
    })
    function changeSection(){
        tabs.forEach(tab => {
            tab.classList.remove('active');
        });
        event.target.classList.add('active');
    }
    

    return content;
}

export { Hub };