function Slideshow(cards){
    
    this.cards = cards;

    this.createSlideshow = function(){

        const slideshow = document.createElement('div');
        slideshow.classList.add('slideshow-container');

        for(let card of this.cards){
            const cardContainer = document.createElement('div');
            cardContainer.classList.add('card-container');
            cardContainer.appendChild(this.createCard(card));
            slideshow.appendChild(cardContainer);
        }

        const activeCard = slideshow.querySelector(':first-child');
        activeCard.classList.add('active');

        return slideshow

    }

    this.createCard =  function(card){
        const Card = document.createElement('img');
        Card.classList.add('card');
        Card.src = card.src;
        Card.alt = card.name;

        return Card;
    }

}

function Slider(slides) {
    
    this.slides = slides;
    
    this.createSlider = function () {
      const slider = document.createElement('div');
      slider.classList.add('slider');
  
      // Slides container
      const slidesContainer = document.createElement('div');
      slidesContainer.classList.add('slides');
      slider.appendChild(slidesContainer);
  
      // Add each slide to the container
      this.slides.forEach((slide, index) => {
        slidesContainer.appendChild(this.createSlide(slide, index));
      });
  
      // Slide controls
      const slideControls = document.createElement('div');
      slideControls.classList.add('slide-controls');
      this.slides.forEach((_, index) => {
        const control = document.createElement('a');
        control.href = `#slide${index + 1}`;
        slideControls.appendChild(control);
      });
      slider.appendChild(slideControls);
  
      return slider;
    };
  
    this.createSlide = function (slide, index) {
      const slideDiv = document.createElement('div');
      slideDiv.classList.add('slide');
      slideDiv.id = `slide${index + 1}`;
  
      const slideImg = document.createElement('img');
      slideImg.src = slide.src;
      slideImg.alt = slide.name; // Assuming you have an 'alt' property in the slide object
  
      slideDiv.appendChild(slideImg);
      return slideDiv;
    };
  }
  

export { Slideshow, Slider };